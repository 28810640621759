// ==========================================
// XXX - DO NOT EDIT THIS FILE BY HAND! - XXX
//
// Automatically generated by:
//   src/scripts/compileYamlLib.ts
//
// ==========================================

const defaultDialect = "eng_us" as const;
const i18nTokenIDs = ["search", "select", "app", "subapp", "searcher_type", "data_source", "license", "welcome_message", "searchers/REGEX", "searchers/EXACT", "searchers/INCLUDES", "searchers/FUZZYSORT", "searchers/LUNR", "searcher_options/case_sensitive", "pages/about", "pages/licenses", "pages/help", "pages/home", "pages/contact"] as const;
const knownDialectIDs = ["eng_us", "mand_tw", "mand_cn"] as const;
const knownFontIDs = ["NotoSansTC", "NotoSansTCBold", "NotoSansSC", "NotoSansSCBold", "SystemSansSerif"] as const;
const knownFontGroupIDs = ["NotoSansTC", "NotoSansTCBold", "NotoSansSC", "NotoSansSCBold", "SystemSansSerif"] as const;

export const i18nTokenIDsSet = new Set(i18nTokenIDs);
export const knownDialectIDsSet = new Set(knownDialectIDs);
export const knownFontIDsSet = new Set(knownFontIDs);
export const knownFontGroupIDsSet = new Set(knownFontGroupIDs);

export type DefaultDialect = typeof defaultDialect;
export type I18NToken = typeof i18nTokenIDs[number];
export type KnownDialectID = typeof knownDialectIDs[number];
export type KnownFontID = typeof knownFontIDs[number];
export type KnownFontGroupID = typeof knownFontGroupIDs[number];